class TestimonialCarousel {
	constructor(block) {
		this.block = block;
		this.init();
	}

	init() {
		new Swiper(this.block.querySelector('.swiper'), {
			slidesPerView: 1,
			autoHeight: true,
			allowTouchMove: false,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			navigation: {
                nextEl: this.block.querySelector('.swiper-button-next'),
                prevEl: this.block.querySelector('.swiper-button-prev'),
            },
			scrollbar: {
                el: this.block.querySelector('.swiper-scrollbar'),
                draggable: true,
            },
		});
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-testimonial-carousel').forEach( block => new TestimonialCarousel(block) );
});
